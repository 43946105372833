/* .about-us{
    
} */
.intro-wraper{
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
    height: 90vh;
}
.intro-left{
    background-image: url('../../../asset/metaIntro.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 70vh;
    position: relative;
}
.intro-left button{
    background-color: rgba(238, 238, 238, 0.548);
    border-radius: 50%;
    border: none;
    height: 50px;
    width: 50px;
    position: absolute;
    top:45%;
    left: 45%;
}
/* .intro-left img{
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 10%; 
} */
.intro-right{
    text-align: start;
    padding:0 50px;
    width: 50%;
    margin-top: 60px;
}

@media only screen and (max-width:992px){
    .intro-wraper{
        flex-direction: column;
        height: auto;
    }
    .intro-left{
        width: 100%;
        height:30vh;
        margin-top: 110px;
        border-radius:0 !important;
    }
    .intro-right{
        text-align: start;
        padding:10px 10px;
        width: 100%;
        margin-top: 0;
    }
}