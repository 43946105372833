@import url(https://fonts.googleapis.com/css?family=Rubik);

 .road-map{
  background-image:
  radial-gradient(
    rgba(179, 226, 8, 0.356) 1%,
    rgba(241, 179, 7, 0.144) 30%,
    #001 60%
  );
 }
 .item{
   width: 320px;
   height: 350px;
   background-color: rgba(123, 125, 126, 0.219);
   text-align: start;
   padding: 20px;
   margin: 50px 30px;
 }
 .card-dot{
   width: 30px;
   height: 30px;
   margin-top: 20px;
   margin-bottom: 20px;
   background-color: rgb(246, 250, 1);
   border-radius: 50%;
   border: 5px solid rgb(175, 172, 8);
 }
 .items{
  position: relative;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
 }
 .items::-webkit-scrollbar {
   height: 20px;
   width: 50% !important;
  
   background-color: rgba(209, 223, 13, 0);
}
.items::-webkit-scrollbar-thumb {
  background-color: rgba(255, 251, 7, 0.205);
  width: 100px;

  border-radius: 20px;
}
.items::after{
 content: '';
 width: 238vw;
 height: 0;
 border-bottom: .5px dotted rgb(255, 255, 255);

 position: absolute;
 left: 52px;
 top: 140px;
 overflow: scroll;
}
.event-heading{
  color:rgb(0, 255, 163);
}

.footer{
  border-top:1px solid #aaa ;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 90px;
  padding-top: 20px;
}
@media only screen and (max-width:992px){
  .items{
    position: relative;
      width: 100vw;
      overflow-x: scroll;
      cursor: pointer;
      display: flex;
   }
   .line-bar{
     display: flex;
     width: auto;
   }
   .items::after{
    content: '';
    width: 0;
   }
   .item{
    width: 320px;
    height: 340px;
    background-color: rgba(123, 125, 126, 0.219);
    text-align: start;
    margin: 10px 30px;
  }
  .footer{
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    top: 30px;
    padding-top: 10px;
  }
}