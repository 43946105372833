.echonomy-btn{
    background-color: rgba(177, 176, 176, 0.144);
    border-radius: 50px;
    border: none;
    color:#fff;
    padding: 10px 20px;
    position: relative;
    padding-right: 60px;
}
.plus-cir{
    position: absolute;
    border: 2px solid #fff;
    border-radius: 50%;
    
    height: 30px;
    width: 30px;
    margin-left: 15px;
    font-weight: 600;
}

@media only screen and (max-width:762px) {
    .token-h2 h2{
        font-size: 16px;
    }
    .token-h2 p{
        font-size: 14px;
    }
}
