.logo-size-style{
    width: 5%;
    position: absolute;
}
.lg-manu-left{
    display: none;
}
.left-btn{
    position: relative;
    display: none;
    /* top:-600px; */
}
.manu-btn-paper{
    height: 45px;
    padding: 5px 30px;
    border-radius: 50px;
    border: 0;
    font-weight: 600;
    background-color:#3dcada;
    margin-top: 10px;
}
.right-btn{
    /* align-items: right; */
    display: flex;
    width: 100%;
    float: right !important;
    justify-content: end !important;
}
.manu-btn{
    color: aliceblue;
    background-color: transparent;
    border: 0;
    margin: 20px 0;
    text-align: left;
    padding:0 30px ;
    /* border-bottom: 1.5px solid #444; */
}
.navbar-toggler:focus:not(:focus-visible) {
    outline: 0;
    color: transparent;
}

.hover-socail-icon{
   position: absolute;
   left: 50px;
   top:88vh;
   background-color: rgba(211, 211, 211, 0.199);
   border: none;
   border-radius: 50px;
   padding: 10px 30px;
   font-size: 30px;
   /* padding: 20px */
}
.social-ico{
    background-color: rgba(255, 255, 255, 0.274);
    /* font-size: 20px; */
    position: relative;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding-top: 10px;
    margin: 0 10px;
}
.ico-display{
 display: none;
}
.hover-socail-icon:hover .ico-display{
    display:inline-block;
}

.select-language{
    background-color: transparent;
    color:#fff;
    border: none;
    margin-right: 40px;
    margin-left: 20px;
    outline: none;
}
.select-language option{
    background-color: transparent;
    color:rgb(0, 0, 0);
    border: none;
    outline: none;
    padding: 10px;
}

/* =============================worked with logo/// */
.logo-gold{
    position: absolute;
    top: 10px;
    left: 20px;
    width: 5%;
     cursor: pointer;
}

 .navbar-expand-lg {
    background-color: #001;
    /*background-color: transparent;*/
 }

@media only screen and (max-width:992px){
    .navbar-expand-lg{
        background-color: #001;
        height: auto;
    }
    .lg-manu-left{
        display: block;
    }
    .lg-manu-right{
        display: none !important;
    }
    .app-download-btn-manu{
        margin-top: 30px !important;
        margin-bottom: 100% !important;
    }
    .social-icon-manu{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px 0;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .social-icon-manu button{
        background-color: rgba(255, 255, 255, 0.479) !important;
        border-radius: 50%;
        /* padding: 10px 10px; */
        height: 40px;
        width: 40px;
        font-size: large;
    }
    .social-icon-manu button{
        background-color: transparent;
        color: aliceblue;
        border: 0;
    }
    .right-btn{
        display: none !important;
    }
    .left-btn{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
    }
  

    /* bottom hover socail icon// */
    .hover-socail-icon{
        left: 10px;
        top:90vh;
        background-color: rgba(211, 211, 211, 0.199);
        border-radius: 50px;
        padding: 5px 10px;
        font-size: 20px;
        /* padding: 20px */
     }
     .social-ico{
         background-color: rgba(255, 255, 255, 0.274);
         /* font-size: 20px; */
         position: relative;
         border-radius: 50%;
         height: 40px;
         width: 40px;
         padding-top: 10px;
         margin: 0 10px;
     }
    /* --------------logo  */
    .logo-gold{
        position: absolute;
        top: 10px;
        left: 20px;
        width: 10%;
       
    }
}
/* ====================================================?? */

