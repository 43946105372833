.slide-text{
    text-align: start;

}
.carousel-control-prev{
    background-color: #000;
    height: 50px;
    width: 50px;
    top: 50%;
    left: 55.5%;
}
.carousel-control-next{
    background-color: #000;
    height: 50px;
    width: 50px;
    top: 50%;
    right:1%;
}
.hero-des{
    color:#aaa !important;
    transition: .5s ease-in-out !important;
}
.carousel-item{
    transition: .5s ease-in-out !important;
}
@media only screen and (max-width:762px) {
    .charecter{
        max-height: 250px;
    }
    .carousel-control-prev{
    
        top: 80%;
        left: 2%;
    }
    .carousel-control-next{
        
        top: 80%;
        right:2%;
    }
    .slide-text{
       margin-left: -20px;
       margin-top: -20px;
    }
    .meta-hero-card{
        margin-left: -20px;
    }
}