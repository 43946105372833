.swiper {
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #001;
  overflow: hidden;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #001;
  color: #fff;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: aliceblue !important;
  height: 40px;
  width: 3px;
  border-radius: 2px !important;
  transition: 1s !important;
  position: relative;
  left: -96.5vw;
  margin-bottom: 10px !important;
}
.swiper-pagination-bullet-active {
  background-color: rgb(254, 255, 255) !important;
  transition: 1s !important;
  box-shadow: 0 0 5px 1px rgb(252, 250, 250);
  width: 3px !important;
}
@media only screen and (max-width: 992px) {
  .swiper {
    width: 100%;
    height: 100vh;
  }
  .swiper-pagination-bullet {
    left: -102vw;
  }
  .swiper-slide {
    overflow-y: scroll !important;
  }
}
