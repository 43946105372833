.banner-background{

        position:absolute;
        width:100%;
        left:50%;
        top:50%;
        height:100%;
        object-fit:cover;
        transform:translate(-50%, -50%);
        z-index:-12;
}
.banaer-video{
        position:fixed !important;
        top:50% !important;
        left:50% !important;
        width:100%;
         height:100%;
         z-index:-12;
}
.app-download{
        background-color:#3dcada;
        border: none;
        color: #000;
        border-radius: 50px;
        padding: 0px 0px;
        font-weight: 800;
        margin: 0px;
}
.app-download:hover{
        background-color:#05c5a5;
       
}

.kyc-download{
        background-color:#0dfd78;
        border: none;
        color: #000;
        border-radius: 50px;
        padding: 0px 0px;
        font-weight: 800;
        margin: 0px;
}
.kyc-download:hover{
        background-color:#058f42;
       
}

.audit-download{
        background-color:#a32cc1;
        border: none;
        color: #000;
        border-radius: 50px;
        padding: 0px 0px;
        font-weight: 800;
        margin: 0px;
}
.audit-download:hover{
        background-color:#980ebb;
       
}
.play-thevideo-btn{
        background-color:transparent;
        border: 2px solid #3dcada;
        color: #3dcada;
        border-radius: 50px;
        padding: 5px 20px;
        font-weight: 800; 
        margin: 50px;
}
.play-thevideo-btn:hover{
        background-color: #05c5a5;
        color:#000;
}
.banner-pair-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top:25%;
        
}
@media only screen and (max-width:762px){
        .banner-pair-btn{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-top:70%;
                
        }    
        .play-thevideo-btn{
                margin: 1px;
        }
        .play-thevideo-btn{
                margin: 1px;
                margin-bottom: 16px;
        }
        .video-heading{
                font-size: 30px !important;
        }
}