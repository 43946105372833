@import url(https://fonts.googleapis.com/css?family=Rubik);

.Introduction {
  background-image: radial-gradient(rgba(255, 204, 0, 0.5) 1%, #001 60%);
  z-index: 9;
}

.section-title p {
  font-size: 14px;
  text-align: left;
}
.section-title h1 {
  text-align: left;
}
.img-shadow {
  position: relative;
  margin-bottom: 30px;
}
.img-shadow::before {
  content: "";
  position: absolute;
  left: -5px;
  top: -15px;
  width: calc(100% - 30px);
  height: 100%;
  background-color: #ffcc00;
  z-index: -1;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .Introduction {
    display: contents;
  }
  .Introduction .swiper {
    height: 100%;
  }
  .Introduction .swiper-slide {
    display: block;
  }
}
