@import url(https://fonts.googleapis.com/css?family=Rubik);

.meet-team {
  background-image: radial-gradient(rgba(255, 204, 0, 0.5) 1%, #001 60%);
}
.meet-team {
  display: flex;
}
.meet-team .swiper {
  width: 100%;
  height: 60vh;
  z-index: 9;
}
.mySwiperteam {
}

.section-title p {
  font-size: 14px;
  text-align: left;
}
.section-title h1 {
  text-align: left;
}
.team-block {
  background-color: #fff;
  width: 100%;
  text-align: left;
  border-radius: 12px;
  overflow: hidden;
}

.team-photo {
  padding: 15px;
  border-radius: 12px;
  overflow: hidden;
}
.team-content {
  padding: 15px;
  padding-top: 0px;
  display: flex;
  align-items: flex-end;
}
.team-content h3 {
  font-size: 18px;
  color: #000011;
  margin: 0;
  margin-bottom: 10px;
}
.team-content p {
  font-size: 14px;
  color: #797979;
  margin: 0;
}
.soc-icons {
  display: flex;
  position: absolute;
  right: 15px;
}
.soc-icons a {
  margin-right: 5px;
}
.soc-icons img {
  height: 22px;
  width: 22px;
}
.meet-team .swiper-button-next,
.meet-team .swiper-button-prev {
  background-color: #ffcc00;
  right: 10px;
  padding: 10px;
  color: #000 !important;
  fill: black !important;
  stroke: black !important;
  border-radius: 100%;
  stroke-width: 15;
  width: 40px;
  height: 40px;
}
.meet-team .swiper-button-next::after,
.meet-team .swiper-button-prev::after {
  font-size: 22px;
}
@media (max-width: 576px) {
  .meet-team {
    display: contents;
  }
  .meet-team .swiper {
    height: 100%;
  }
  .meet-team .swiper-slide {
    display: block;
  }
}
