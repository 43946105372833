.echo-logo{
    background-color: rgba(182, 183, 184, 0.164);
    border: 1px solid #aaa;
    border-radius: 10px;
    padding: 10px 20px;
}
.partner-wraper{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    background-color: rgba(182, 183, 184, 0.164);
    border-radius: 20px;

}
.partner-wraper img{
    width:12.5%;
    padding: 10px;
} 